import { TextInput, TextInputProps } from './TextInput';

import { TextInputField } from '.';

export interface CurrencyInputProps extends TextInputProps {
  allowCents?: boolean;
}

export const CurrencyInput = ({ allowCents = true, ...props }: CurrencyInputProps) => {
  const C = props.name ? TextInputField : TextInput;

  return (
    // @ts-ignore Dynamic component, no problem
    (<C
      inputMode="decimal"
      placeholder={allowCents ? '$0.00' : '$0'}
      options={{
        mask: '$XX',
        blocks: {
          XX: {
            mask: Number,
            thousandsSeparator: ',',
            radix: '.',
            scale: allowCents ? 2 : 0,
            normalizeZeros: true,
            padFractionalZeros: true,
          },
        },
      }}
      {...props}
    />)
  );
};
