import { FormikErrors, useField } from 'formik';
import { type IconDefinition as LightIconDefinition } from '@fortawesome/fontawesome-common-types';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import React, { ReactNode, useState } from 'react';

import { type IconDefinition as BrandsIconDefinition } from '@fortawesome/free-brands-svg-icons';
import { DEFAULT_SPRING_CONFIG } from '@/utils/animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMaskInput } from 'react-imask';
// import Typography from '@/components/atoms/Typography';
import cn from 'classnames';
import { m } from 'framer-motion';
import LoadingSpinner from '@/components/atoms/LoadingSpinner';
import Typography from '@/components/atoms/@next/Typography';

export interface TextInputProps extends Omit<React.ComponentProps<'input'>, 'ref'> {
  className?: string;
  type?: React.HTMLInputTypeAttribute;
  // TODO: Refactor so icons are passed in as component instances
  // rather than references to a FontAwesome icon. Then we won't
  // need callbacks like onLeftIconClick and onRightIconClick
  iconLeft?: LightIconDefinition | BrandsIconDefinition;
  iconRight?: LightIconDefinition | BrandsIconDefinition;
  placeRight?: ReactNode;
  onLeftIconClick?: AnyFunction;
  onRightIconClick?: AnyFunction;
  onClear?: (fieldName?: string) => any;
  loading?: boolean;
  label?: string;
  options?: any;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  value?: any;
  tip?: string;
  variant?: 'square' | 'rounded';
  showMessages?: boolean;
  shadow?: boolean;
}

export interface TextInputFieldProps extends TextInputProps {
  name: string;
}

export interface TextInputIconProps extends React.ComponentProps<typeof FontAwesomeIcon> {
  name: string;
  className?: string;
}

export const TextInput = ({
  type = 'text',
  className,
  iconLeft,
  iconRight,
  placeRight,
  loading,
  label,
  tip,
  onClear,
  onLeftIconClick,
  onRightIconClick,
  error,
  options,
  variant = 'rounded',
  showMessages = true,
  shadow = false,
  ...props
}: TextInputProps) => {
  const [hasFocus, setFocus] = useState(false);
  const isDisabled = loading || props.disabled;
  const isClearable = !!(hasFocus && props.value && !isDisabled && onClear);

  const clearButtonAnimationVariants = {
    invisible: { transform: 'translateX(500%)' },
    visible: { transform: 'translateX(0%)' },
  };

  const textBelowInputAnimationVariants = {
    invisible: { transform: 'translateY(-50%)', opacity: 0 },
    visible: { transform: 'translateY(0%)', opacity: 1 },
  };

  return (
    <div
      className="grid w-full grid-flow-row gap-2"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="relative flex flex-col gap-2">
        {label && (
          <label
            id={props.id || props.name}
            className="absolute rounded-lg text-sm text-sumawealth-blue-100 duration-300 transform -translate-y-4 scale-75 top-6 z-10 origin-[5rem] bg-white px-2 peer-focus:px-2 peer-focus:text-sumawealth-blue-100 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:origin-[5] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            {label}
          </label>
        )}
        <div
          className={cn(
            'relative flex items-center gap-4 overflow-hidden bg-white transition',
            {
              'text-gray-dark': isDisabled,
              'text-cacao': !isDisabled,
              'ring-2 ring-error': error,
              'overflow-hidden rounded-xl shadow-none': variant === 'rounded',
              'shadow drop-shadow-md relative z-20': shadow,
            },
            'border-sumawealth-blue-100 border-[1px] appearance-none focus:outline-none',
            'block font-work-sans text-[24px] leading-[32px] font-normal w-full text-sm text-sumawealth-blue-100 bg-sumawealth-neutral-0 my-4 border-sumawealth-blue-100 border-[1px] appearance-none focus:outline-none focus:ring-0 focus:border-sumawealth-blue-100 peer'
          )}
        >
          <div className={cn('flex w-full items-center gap-4 px-5 transition-all')}>
            {iconLeft && (
              <FontAwesomeIcon
                className={cn('fa-xl', {
                  'cursor-pointer': onLeftIconClick,
                })}
                icon={iconLeft}
                onClick={onLeftIconClick}
              />
            )}
            <IMaskInput
              onAccept={(value: any) =>
                props.onChange?.({
                  // @ts-ignore Hack to use Formik with react-imask until we switch
                  // mask libraries
                  target: {
                    name: props.name || '',
                    id: props.id || '',
                    value,
                  },
                })
              }
              className={cn(
                'w-full flex-grow font-body text-sm md:text-base placeholder-gray-dark outline-none disabled:cursor-not-allowed bg-sumawealth-neutral-0 py-4',
                {
                  'rounded-xl': variant === 'rounded',
                  'rounded-none': variant === 'square',
                },
                className
              )}
              disabled={isDisabled}
              type={type}
              {...options}
              {...props}
            />
            {loading && (
              <span className="text-berry" data-test-id="loading-spinner">
                <LoadingSpinner size="sm" />
              </span>
            )}
            {placeRight}
            {iconRight && !loading && (
              <FontAwesomeIcon
                className={cn('fa-xl', {
                  'cursor-pointer': onRightIconClick,
                })}
                icon={iconRight}
                onClick={onRightIconClick}
              />
            )}
            {onClear && (
              <m.div
                className="relative flex"
                animate={isClearable ? 'visible' : 'invisible'}
                transition={DEFAULT_SPRING_CONFIG}
                variants={clearButtonAnimationVariants}
                initial={false}
              >
                <div
                  className="absolute left-1/2 top-1/2 z-10 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer"
                  onMouseDown={() => onClear(props.name)}
                ></div>
                <FontAwesomeIcon icon={faTimes} className="fa-lg relative" />
              </m.div>
            )}
          </div>
        </div>
        {showMessages && (
          <div className="relative text-left mx-2">
            <m.div
              className="absolute bottom-0 w-full"
              animate={error ? 'visible' : 'invisible'}
              variants={textBelowInputAnimationVariants}
              transition={DEFAULT_SPRING_CONFIG}
              initial={false}
            >
              <Typography variant="body" size="14px" className="truncate !text-error">
                {error}
              </Typography>
            </m.div>
            <m.div
              className="absolute bottom-0"
              animate={tip && !error && hasFocus && props.value ? 'visible' : 'invisible'}
              variants={textBelowInputAnimationVariants}
              transition={DEFAULT_SPRING_CONFIG}
              initial={false}
            >
              <Typography variant="body" className="italic">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 not-italic" />
                {tip}
              </Typography>
            </m.div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TextInputIcon = ({ icon }: TextInputIconProps) => (
  // The flex div here is a hacky way to center the icon vertically
  (<div className="flex">
    <FontAwesomeIcon className="fa-lg" icon={icon}></FontAwesomeIcon>
  </div>)
);

export const TextInputField: React.FC<TextInputFieldProps> = (props) => {
  const [field, meta, helper] = useField(props.name);

  return (
    <TextInput
      onClear={() => helper.setValue('')}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};
