import { TextInput, TextInputField } from '.';

import { TextInputProps } from './TextInput';

interface CreditCardNumberInputProps extends TextInputProps {}

export const CreditCardNumberInput = (props: CreditCardNumberInputProps) => {
  const C = props.name ? TextInputField : TextInput;

  return (
    // @ts-ignore Dynamic component, no problem
    (<C
      inputMode="decimal"
      options={{
        mask: '0000 0000 0000 0000',
        lazy: true,
        unmask: true,
      }}
      placeholder="e.g. 1234 5678 9012 3456"
      autoComplete="cc-number"
      {...props}
    />)
  );
};
