import { TextInput, TextInputField, TextInputProps } from './TextInput';

import React from 'react';
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile';

export interface PhoneNumberInputProps extends TextInputProps {}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const C = props.name ? TextInputField : TextInput;
  return (
    // @ts-ignore Dynamic component, no problem
    (<C
      iconLeft={faMobile}
      inputMode="tel"
      options={{
        mask: '{+1} (000) 000 - 0000',
        lazy: false,
        unmask: true,
      }}
      placeholder="+1 (999) 999 - 9999"
      {...props}
    />)
  );
};
