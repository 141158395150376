import { TextInput, TextInputProps } from './TextInput';

import { TextInputField } from '.';

export interface ZipCodeInputProps extends TextInputProps {}

export const ZipCodeInput = ({ ...props }: ZipCodeInputProps) => {
  const C = props.name ? TextInputField : TextInput;

  return (
    // @ts-ignore Dynamic component, no problem
    (<C
      inputMode="numeric"
      options={{
        mask: '00000-0000',
      }}
      autoComplete="postal-code"
      {...props}
    />)
  );
};
